import React, { useState } from 'react';
import useStyles from './styles';
import { Row, Col, useScreenClass } from 'react-grid-system';
import MapPinIcon from '../../assets/icons/Map_Pin_icon.svg';
import FormButton from '../FormButton';
import HorseProfileField from '../HorseProfileField';
import HorseInterestForm from '../HorseInterestForm';
import { useToast } from '../../hooks/useToast';
import AppSyncService from '../../graphql/AppSyncService';
import { Link } from 'gatsby';
import { useMount } from '../../hooks';
import {
	placeHorseInterest,
	submitHorsePlacementInterest,
	noLongerInterested,
	removeHorsePlacementListing,
	saveRetrainerHorseStatus,
	saveDismissableNotif
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import InfoModal from '../InfoModal';
import {
	AVAILABLE_FOR_RETRAINING,
	RESET_HORSE_EXPRESS,
	RETRAINER_IS_INTERESTED
} from '../../utils/constants';
import HorseListingEditForm from '../HorseListingEditForm';
import {
	addNameToUnnamedHorse,
	formatDateReadableVariant,
	formatDateTimeReadable,
	parseUTCToLocal,
	formatFormSummary,
	checkIsSmallScreen
} from '../../utils/helpers';
import ImageSlider from '../ImageSlider';
import FormLabelField from '../FormLabelField';
import HeaderBanner from '../HeaderBanner';
import { getHorsePlacementListing } from './queries';
import moment from 'moment';
import FormToolTip from '../FormToolTip';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import InfoBar from '../InfoBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import { logToServer } from '../../utils/logger';
import { useAuth } from '../../hooks/useAuth';
import ResetExpressTag from '../ResetExpressTag';

const TAGS_TOTAL_LENGTH = 200;

const infoText =
	'This thoroughbred is eligible for the Cat B/C Transition Subsidy';

const HorseDetailsInfo = ({
	data,
	placementData,
	isOwner,
	isAssigned,
	isClaimed,
	isAdmin,
	isRetrainer,
	horseOTTStatusID
}) => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const [isInterested, setIsInterested] = useState(
		placementData?.status === 'Interested'
	);
	const screenSize = useScreenClass();
	const { addToast } = useToast();
	const [retrainerConfirmation, setRetrainerConfirmation] = useState(false);
	const [placementRemovalConfirmed, setPlacementRemovalConfirmed] = useState(
		false
	);
	const [showModal, setShowModal] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [formId, setFormId] = useState(placementData?.id || null);
	const [horseInterestData, setHorseInterestData] = useState([]);
	const [lastReviewed, setLastReviewed] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [reasonBCTS, setReasonBCTS] = useState([]);

	const isSmallScreen = checkIsSmallScreen(screenSize);
	const updatePage = () => {
		setShowModal(false);
		navigate('/search', {
			state: { searchSelected: true, search: AVAILABLE_FOR_RETRAINING }
		});
	};

	const isResetExpress = RESET_HORSE_EXPRESS.includes(horseOTTStatusID);
	const statusDisplay = data?.latestRESETExpressQualification?.value;
	const resetExpressInfoText = (
		<>
			This thoroughbred is eligible for the RESET 2 subsidy - {statusDisplay}.
			Please contact{' '}
			<a href="mailto:offthetrack@racingvictoria.net.au">
				offthetrack@racingvictoria.net.au
			</a>{' '}
			if you need more information about the subsidy, or if you are taking this
			horse so we can remove the listing.
		</>
	);

	const modalStyles = {
		content: {
			width: 500,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			maxWidth: '80%'
		},
		overlay: {
			zIndex: 5
		}
	};

	const renderOwnerRetrainer = (mobile) => {
		let owners = '';
		let retrainers = '';
		let ownerRetrainerList = [];
		if (data.currentOwners != null) {
			owners = data.currentOwners.split(',');
		}
		if (data.currentRetrainers != null) {
			retrainers = data.currentRetrainers.split(',');
		}
		if (owners != '' && retrainers != '') {
			ownerRetrainerList = owners.concat(retrainers);
		} else if (owners != '' && retrainers === '') {
			ownerRetrainerList = owners;
		} else if (owners === '' && retrainers != '') {
			ownerRetrainerList = retrainers;
		}
		return ownerRetrainerList.map((t, index) => {
			return (
				<React.Fragment key={index}>
					<HorseProfileField
						field={index === 0 ? 'Owner/Trainers' : ''}
						noline
						value={t}
						mobile={mobile}
						fieldStyle={{ color: '#464749' }}
					/>
				</React.Fragment>
			);
		});
	};

	useMount(() => {
		if (isAdmin) {
			AppSyncService.execute(getHorsePlacementListing, {
				horseCode: placementData?.horse?.horseCode
			}).then((data) => {
				if (data?.data?.getHorsePlacementListing) {
					setHorseInterestData(
						data.data.getHorsePlacementListing.interestHistory
					);
					setLastReviewed(data.data.getHorsePlacementListing.lastApproved);
					setReasonBCTS(data.data.getHorsePlacementListing.reasonRetirement);
				}
			});
		}
	});

	const confirmRetrainerInterest = (
		contactOwner,
		acknowledged,
		dateArranged,
		date
	) => {
		// dateArragned maybe needed in future
		AppSyncService.execute(submitHorsePlacementInterest, {
			id: formId,
			contactOwner: contactOwner,
			collectDate: date,
			acknowledged: acknowledged
		}).then(async (data) => {
			if (data.data?.submitHorsePlacementInterest?.success) {
				try {
					await AppSyncService.execute(saveDismissableNotif, {
						externalId: placementData?.horsePlacementForm?.member?.externalId,
						autoDismissDate: '9999-01-01',
						source: RETRAINER_IS_INTERESTED,
						message: getTrainerIsInterestedMessage(placementData?.horse?.name),
						link: `/contact-us`
					});
				} catch (error) {
					logToServer(
						currentUser?.UserId,
						error,
						`AvailableForRetrainReview => dismissableNotifResult`
					);
				}
				addToast({ Message: 'Interest Placement Completed', IsSuccess: true });
				setRetrainerConfirmation(true);
			} else {
				addToast({ Message: 'Unable to update Interest', IsSuccess: false });
			}
		});
	};

	const confirmPlacementRemovalAction = (
		id,
		removeDetails,
		removeReasonId,
		removeReasonOther
	) => {
		AppSyncService.execute(removeHorsePlacementListing, {
			id: id,
			removeDetails: removeDetails,
			removeReasonId: removeReasonId,
			removeReasonOther: removeReasonOther
		}).then((data) => {
			if (data.data?.removeHorsePlacementListing?.success) {
				addToast({
					Message: 'Your listing has been successfully removed',
					IsSuccess: true
				});
				setPlacementRemovalConfirmed(true);
			} else {
				addToast({
					Message: 'Unable to update Horse placement listing',
					IsSuccess: false
				});
			}
			navigate(DASHBOARD_ROUTE);
		});
	};

	const noLongerInterestedInHorse = (nolongerInterestedreason, moreInfo) => {
		AppSyncService.execute(noLongerInterested, {
			horsePlacementInterestId: formId,
			noLongerInterestedId: nolongerInterestedreason,
			details: moreInfo
		}).then((response) => {
			if (response.data?.noLongerInterestedHorsePlacement?.success) {
				const input = {
					horseCode: placementData?.horse?.horseCode,
					statusId: !data?.eligibilityForBCTS ? 6 : 31 //If not eligible set to Listed else BCTS, Listed
				};
				AppSyncService.execute(saveRetrainerHorseStatus, {
					input
				});
				addToast({ Message: 'Interest Updated', IsSuccess: true });
				navigate('/search', {
					state: { searchSelected: true, search: AVAILABLE_FOR_RETRAINING }
				});
			} else {
				addToast({ Message: 'Unable to update Interest', IsSuccess: false });
			}
		});
	};

	const renderTags = (mobile) => {
		let totalLength = 0;
		let disciplines = [];
		let tempString = '';
		if (data.disciplines.length > 0) {
			data.disciplines.map((t) => {
				let value = t.value;
				totalLength += tempString.concat(value).length;
				if (totalLength < TAGS_TOTAL_LENGTH) {
					disciplines.push(t.value);
				}
			});
			return disciplines.map((t) => {
				return (
					<div key={t} className={mobile ? classes.mobileTags : classes.tags}>
						{t}
					</div>
				);
			});
		}
	};

	const retrainerInterestRow = (data, index) => {
		return (
			<Row key={index}>
				<Col className={classes.historyText}>
					<span className={classes.interestHistory}>
						{`${data.member.firstName} ${data.member.lastName}: `}
						{data.status === 'NoLongerInterested'
							? `No Longer Interested - ${data?.noLongerInterested?.value} - "${data?.noLongerInterestedDetails}."`
							: ''}
						{data.status === 'FollowedUp' ? `Followed Up.` : ''}
						{data.status === 'Interested' && `Interested - `}
						{data.status === 'Interested' &&
							(moment().isAfter(parseUTCToLocal(data.expires))
								? `12 Hours Elapsed.`
								: `Has yet to expire.`)}
						{data.collectDate &&
							` Collect Date: ${parseUTCToLocal(data.collectDate).format(
								'DD/MM/YYYY'
							)}`}
					</span>
					<span
						className={classes.historyTimestamp}
					>{`  - ${formatDateTimeReadable(data.created)}`}</span>
				</Col>
			</Row>
		);
	};
	const renderHorseDetails = (mobile) => {
		return (
			<>
				<HorseProfileField
					field="Sire name"
					value={placementData.horse.sireName}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Dam name"
					value={placementData.horse.damName}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Age"
					value={data.horse?.age}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Sex"
					value={data.horse?.sex}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Approx. height"
					value={data.height}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Form summary"
					value={formatFormSummary(
						placementData?.horse?.racingSummary?.raceTypeSummary
					)}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Date last raced"
					value={
						placementData?.horse?.racingSummary?.dateLastRaced
							? formatDateReadableVariant(
									placementData?.horse?.racingSummary?.dateLastRaced
							  )
							: '-'
					}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				{isAdmin && data.lastReviewed && (
					<HorseProfileField
						field="Date approved"
						value={parseUTCToLocal(data.lastReviewed).format('DD/MM/YYYY')}
						mobile={mobile}
						fieldStyle={{ color: '#464749' }}
					/>
				)}
				<HorseProfileField
					field="Reason for retirement"
					value={
						data.retirementReason?.value === 'Other'
							? data.retirementOtherReason
							: data.retirementReason?.value
					}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Has this horse been known to windsuck"
					value={data.isKnownToWindsuck?.value}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Owner willing to contribute towards retraining costs"
					value={data.contributeRetrainingCosts ? 'Yes' : 'No'}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Owner willing to contribute to transport costs"
					value={data.isHelpingTransportCosts ? 'Yes' : 'No'}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<HorseProfileField
					field="Suggested discipline(s):"
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
					noline={true}
				/>
				<div className={classes.tagsContainer}>{renderTags(mobile)}</div>
			</>
		);
	};

	const renderContact = (mobile) => {
		return (
			<>
				<HorseProfileField
					field="Contact Name"
					value={`${data.member?.firstName} ${data.member?.lastName}`}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => setShowEmail(!showEmail)}
				>
					<HorseProfileField
						field="Contact Email"
						value={data.member?.email}
						mobile={mobile}
						valueStyle={{
							maxWidth: 300,
							whiteSpace: mobile ? '' : 'nowrap',
							textOverflow: mobile ? '' : 'ellipsis',
							overflow: mobile ? '' : 'hidden',
							wordBreak: mobile ? 'break-all' : ''
						}}
						fieldStyle={{ color: '#464749' }}
					/>
				</div>
				{showEmail && (
					<span className={classes.tooltip}>{data.member?.email}</span>
				)}
				<HorseProfileField
					field="Contact Number"
					value={data.contactNumber || data.member?.phone}
					mobile={mobile}
					fieldStyle={{ color: '#464749' }}
				/>
				{renderOwnerRetrainer(mobile)}
			</>
		);
	};

	const getTrainerIsInterestedMessage = (horseName) => {
		return `A Retrainer has advised they are taking ${horseName} and we have removed the listing. 
			If this is not correct, please Contact Us`;
	};

	const updateInterested = () => {
		setIsLoading(true);
		AppSyncService.execute(placeHorseInterest, {
			horseCode: placementData.horse.horseCode,
			horsePlacementFormId: data.id
		}).then((data) => {
			if (data.data.saveHorsePlacementInterest.success) {
				setIsInterested(true);
				addToast({ Message: 'Interest Updated', IsSuccess: true });
				setFormId(data.data.saveHorsePlacementInterest.id);
			} else {
				setErrorMessage(
					data.data?.saveHorsePlacementInterest?.error?.errorMessage
				);
				setIsInterested(false);
				addToast({
					Message: 'Unable to place horse interest',
					IsSuccess: false
				});
				setShowModal(true);
			}
			setIsLoading(false);
		});
	};

	const listOfImages = () => {
		let imageList = [];
		data.faceOnPhotos
			.filter((a) => a.isApproved)
			.map((a) => {
				imageList.push({ image: a.key });
			});
		data.leftSidePhotos
			.filter((a) => a.isApproved)
			.map((a) => {
				imageList.push({ image: a.key });
			});
		data.rightSidePhotos
			.filter((a) => a.isApproved)
			.map((a) => {
				imageList.push({ image: a.key });
			});
		data.behindFullPhotos
			.filter((a) => a.isApproved)
			.map((a) => {
				imageList.push({ image: a.key });
			});
		data.additionalPhotos
			.filter((a) => a.isApproved)
			.map((a) => {
				imageList.push({ image: a.key });
			});
		return imageList;
	};

	const getInjuryList = () => {
		let injuries = '';
		if (data.injuries != null && data.injuries.length > 0) {
			data.injuries.map((i, index) => {
				injuries = injuries.concat(`${index === 0 ? '' : ','} ${i.value}`);
			});
		}
		return injuries.length > 0 ? injuries : '-';
	};

	const getCurrentVices = () => {
		return data?.vicesCurrent?.length > 0
			? data?.vicesCurrent?.map((vices) => vices.value).join(', ')
			: '-';
	};

	const getPastVices = () => {
		return data?.vicesPast?.length > 0
			? data?.vicesPast?.map((vices) => vices.value).join(', ')
			: '-';
	};

	const getPastInjuryList = () => {
		return data?.injuriesPast?.length > 0
			? data?.injuriesPast?.map((injuries) => injuries.value).join(', ')
			: '-';
	};

	const getIllnessList = () => {
		return data?.illnesses?.length > 0
			? data?.illnesses?.map((illness) => illness.value).join(', ')
			: '-';
	};

	return (
		<>
			<Row className={classes.topContainer}>
				{data.eligibilityForBCTS || isResetExpress ? (
					<Col xs={12} lg={12} style={{ marginBottom: 20 }}>
						<InfoBar
							type={MessageBarTypeEnum.info}
							infoText={isResetExpress ? resetExpressInfoText : infoText}
							noButton
							style={{ padding: '5px 20px' }}
						/>
					</Col>
				) : null}

				<Col xs={12} lg={6}>
					<div
						className={
							isSmallScreen ? classes.mobileHorseName : classes.horseName
						}
					>
						<Link
							to={`/horse-profile/${placementData?.horse?.horseCode}`}
							className={classes.viewHorseProfileLink}
							id={`availableForRetraining-horseDetailsInfo-horseName`}
						>
							{addNameToUnnamedHorse(data.horse?.name)}
						</Link>
					</div>
					<div>
						<img src={MapPinIcon} className={classes.mapIcon} />
						<span
							className={
								isSmallScreen ? classes.mobileLocation : classes.location
							}
						>
							{data.suburb}, {data.postcode}
						</span>
					</div>
				</Col>
				{!isResetExpress && !isOwner && !isAdmin && isRetrainer && (
					<Col xs={12} lg={6}>
						<div
							className={
								isSmallScreen
									? classes.mobileButtonContainer
									: classes.buttonContainer
							}
						>
							<FormButton
								id="availableForRetraining-horseDetailsInfo-interested"
								title="I'm Interested"
								styles={{ fontSize: 15 }}
								isDisabled={
									placementData.status === null && !formId
										? !isLoading
											? false
											: true
										: true
								}
								onClick={() => updateInterested()}
							/>
						</div>
					</Col>
				)}
			</Row>
			<Row>
				<Col>
					{isSmallScreen && <ImageSlider sliderImages={listOfImages()} />}
					{!isSmallScreen && (
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<ImageSlider sliderImages={listOfImages()} />
							<ImageSlider sliderImages={listOfImages()} startIndex={1} />
						</div>
					)}
				</Col>
			</Row>

			<Row>
				{!isSmallScreen &&
					((isAssigned && !isClaimed) ||
						isOwner ||
						isAdmin ||
						isInterested) && (
						<Col>
							<div className={classes.bodyDetails}>
								{renderContact(isSmallScreen)}
							</div>
						</Col>
					)}
				<Col>
					{!isOwner && !isAdmin && isInterested && (
						<HorseInterestForm
							confirmRetrainerAction={confirmRetrainerInterest}
							retrainingConfirmed={retrainerConfirmation}
							noLongerInterestedAction={noLongerInterestedInHorse}
							mobile={isSmallScreen}
						/>
					)}
					{isSmallScreen &&
						((isAssigned && !isClaimed) ||
							isOwner ||
							isAdmin ||
							isInterested) && (
							<div className={classes.mobileBodyDetails}>
								{renderContact(true)}
							</div>
						)}
					{(isOwner || isAdmin) &&
						placementData &&
						data &&
						horseOTTStatusID && (
							<HorseListingEditForm
								isResetExpress={isResetExpress}
								placementData={placementData}
								confirmPlacementRemovalAction={confirmPlacementRemovalAction}
								placementRemovalConfirmed={placementRemovalConfirmed}
								mobile={isSmallScreen}
								horseOTTStatusID={horseOTTStatusID}
							/>
						)}
				</Col>
			</Row>
			{isAdmin && (
				<>
					<hr className={classes.divider}></hr>
					<HeaderBanner
						title="Admin"
						inline
						type="primary"
						styles={{ marginBottom: '0px', marginTop: '0px' }}
					/>
					<div className={classes.adminContainer}>
						{lastReviewed && (
							<Row>
								<Col>
									<FormLabelField
										title="Date Approved"
										value={formatDateTimeReadable(lastReviewed)}
									/>
								</Col>
							</Row>
						)}
						<Row style={{ marginTop: -10 }}>
							<Col>
								<FormLabelField
									title="Activity"
									value={horseInterestData.map((data, index) => (
										<>{retrainerInterestRow(data, index)}</>
									))}
								/>
							</Col>
						</Row>
						{data.eligibilityForBCTS ? (
							<Row style={{ marginTop: -10 }}>
								<Col>
									<FormLabelField
										title="Reason for Cat B/C Transition Subsidy"
										value={reasonBCTS.map((reason) => reason.value).join(', ')}
									/>
								</Col>
							</Row>
						) : null}
					</div>
				</>
			)}
			<hr className={classes.divider}></hr>
			<Row>
				{isSmallScreen && (
					<Col xs={12}>
						<div className={classes.mobileHeading}>DETAILS</div>
						<div className={classes.mobileBodyDetails}>
							{renderHorseDetails(true)}
						</div>
					</Col>
				)}
				<Col xs={12} lg={6}>
					{data.riddenHistory && (
						<div
							className={
								isSmallScreen ? classes.mobileHeading : classes.heading
							}
						>
							RIDDEN HISTORY / EDUCATION
						</div>
					)}
					{data.riddenHistory && (
						<div className={isSmallScreen ? classes.mobileBody : classes.body}>
							{data.riddenHistory}
						</div>
					)}
					{data.temperament && (
						<div
							className={
								isSmallScreen ? classes.mobileHeading : classes.heading
							}
						>
							TEMPERAMENT
						</div>
					)}
					{data.temperament && (
						<div className={isSmallScreen ? classes.mobileBody : classes.body}>
							{data.temperament}
						</div>
					)}
					<div
						className={isSmallScreen ? classes.mobileHeading : classes.heading}
					>
						INJURY & ILLNESS
					</div>
					<div className={isSmallScreen ? classes.mobileBody : classes.body}>
						{/* start of reset */}
						<HorseProfileField
							styles={{ marginLeft: 0, marginRight: 0 }}
							field="Does this horse have any current injuries?"
							fieldStyle={{ color: '#464749' }}
							value={
								data.injuries != null && data.injuries.length > 0 ? 'Yes' : 'No'
							}
							mobile={isSmallScreen}
						/>
						{data?.injuries != null && data?.injuries.length > 0 ? (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Current injury type(s)"
								fieldStyle={{ color: '#464749' }}
								value={getInjuryList()}
								mobile={isSmallScreen}
							/>
						) : null}
						{data.injuries != null &&
						data.injuries.length > 0 &&
						data?.injuryOther ? (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Current injury type(s) - Other"
								fieldStyle={{ color: '#464749' }}
								value={data?.injuryOther ? data?.injuryOther : '-'}
								mobile={isSmallScreen}
							/>
						) : null}

						<HorseProfileField
							styles={{ marginLeft: 0, marginRight: 0 }}
							field="Veterinarian report/s available?"
							fieldStyle={{ color: '#464749' }}
							value={
								data.vetReports.length > 0 ? (
									<>
										Yes{' '}
										<FormToolTip
											type="questionMark"
											toolTipContainerStyle={{
												fontWeight: 'normal',
												...(isSmallScreen && {
													top: -80,
													left: -200
												})
											}}
										>
											Please contact RV OTT team if you are interested in the
											report/s
										</FormToolTip>
									</>
								) : (
									'No'
								)
							}
							mobile={isSmallScreen}
						/>

						<HorseProfileField
							styles={{ marginLeft: 0, marginRight: 0 }}
							field="Does this injury impact the horses soundness?"
							fieldStyle={{ color: '#464749' }}
							value={data?.isImpactSoundness ? 'Yes' : 'No'}
							mobile={isSmallScreen}
						/>

						<HorseProfileField
							styles={{ marginLeft: 0, marginRight: 0 }}
							field="Has this horse ever been injured?"
							fieldStyle={{ color: '#464749' }}
							value={data?.isPastInjuryImpactedHorse ? 'Yes' : 'No'}
							mobile={isSmallScreen}
						/>

						{data?.isPastInjuryImpactedHorse && (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Previous injury type(s)"
								fieldStyle={{ color: '#464749' }}
								value={getPastInjuryList()}
								mobile={isSmallScreen}
							/>
						)}

						{data?.isPastInjuryImpactedHorse && data?.injuryPastOther && (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Previous injury type(s) - Other"
								fieldStyle={{ color: '#464749' }}
								value={data?.injuryPastOther ? data?.injuryPastOther : '-'}
								mobile={isSmallScreen}
							/>
						)}

						<HorseProfileField
							styles={{ marginLeft: 0, marginRight: 0 }}
							field="Does this horse have any current illness?"
							fieldStyle={{ color: '#464749' }}
							value={data?.isIllnessImpactedHorse > 0 ? 'Yes' : 'No'}
							mobile={isSmallScreen}
						/>

						{data?.isIllnessImpactedHorse && (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Current Illness type(s)"
								fieldStyle={{ color: '#464749' }}
								value={getIllnessList()}
								mobile={isSmallScreen}
							/>
						)}

						{data?.isIllnessImpactedHorse && data?.illnessOther && (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Illness type(s) - Other"
								fieldStyle={{ color: '#464749' }}
								value={data?.illnessOther ? data?.illnessOther : '-'}
								mobile={isSmallScreen}
							/>
						)}

						{data?.illnessAndInjuriesDetails && (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Details of provided vet care / rehab support"
								fieldStyle={{ color: '#464749' }}
								value={data?.illnessAndInjuriesDetails ? '' : '-'}
								mobile={isSmallScreen}
								noline
							/>
						)}

						{data?.illnessAndInjuriesDetails && (
							<div
								className={isSmallScreen ? classes.mobileBody : classes.body}
							>
								{data?.illnessAndInjuriesDetails}
							</div>
						)}
					</div>
					{data.otherDetails && (
						<div
							className={
								isSmallScreen ? classes.mobileHeading : classes.heading
							}
						>
							VICES & BEHAVIOURS
						</div>
					)}

					{/* start of reset */}
					<HorseProfileField
						styles={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}
						field="Does this horse have any current vices?"
						fieldStyle={{ color: '#464749' }}
						value={data?.doesHorseHasCurrentVices ? 'Yes' : 'No'}
						mobile={isSmallScreen}
					/>

					{data?.doesHorseHasCurrentVices && data?.vicesCurrent?.length > 0 && (
						<HorseProfileField
							styles={{ marginLeft: 0, marginRight: 0 }}
							field="Current vices"
							fieldStyle={{ color: '#464749' }}
							value={getCurrentVices()}
							mobile={isSmallScreen}
						/>
					)}

					{data?.doesHorseHasCurrentVices &&
						data?.vicesCurrent?.length > 0 &&
						data?.habitsCurrentOther && (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Current vice(s) - Other"
								fieldStyle={{ color: '#464749' }}
								value={
									data?.habitsCurrentOther ? data?.habitsCurrentOther : '-'
								}
								mobile={isSmallScreen}
							/>
						)}

					<HorseProfileField
						styles={{ marginLeft: 0, marginRight: 0 }}
						field="Has this horse ever had any reported vices?"
						fieldStyle={{ color: '#464749' }}
						value={data?.doesHorseHasPastVices ? 'Yes' : 'No'}
						mobile={isSmallScreen}
					/>

					{data?.doesHorseHasPastVices && data?.vicesPast?.length > 0 && (
						<HorseProfileField
							styles={{ marginLeft: 0, marginRight: 0 }}
							field="Previous vices"
							fieldStyle={{ color: '#464749' }}
							value={getPastVices()}
							mobile={isSmallScreen}
						/>
					)}

					{data?.doesHorseHasPastVices &&
						data?.vicesPast?.length > 0 &&
						data?.habitsPastOther && (
							<HorseProfileField
								styles={{ marginLeft: 0, marginRight: 0 }}
								field="Previous vice(s) - Other"
								fieldStyle={{ color: '#464749' }}
								value={data?.habitsPastOther ? data?.habitsPastOther : '-'}
								mobile={isSmallScreen}
							/>
						)}

					{data.otherDetails && (
						<HorseProfileField
							styles={{ marginLeft: 0, marginRight: 0 }}
							field="Any other relevant matters reported?"
							fieldStyle={{ color: '#464749' }}
							value={data.otherDetails ? 'Yes' : '-'}
							mobile={isSmallScreen}
							noline
						/>
					)}
					{/* end of reset */}

					{data.otherDetails && (
						<div className={isSmallScreen ? classes.mobileBody : classes.body}>
							{data.otherDetails}
						</div>
					)}

					{isResetExpress && (
						<ResetExpressTag
							isRemoveMargin
							latestRESETExpressQualification={
								data.latestRESETExpressQualification?.value
							}
						/>
					)}
				</Col>
				{!isSmallScreen && (
					<Col>
						<div className={classes.heading}>DETAILS</div>
						<div className={classes.bodyDetails}>
							{renderHorseDetails(false)}
						</div>
					</Col>
				)}
			</Row>
			<InfoModal
				showModal={showModal}
				closeModal={() => updatePage()}
				styles={modalStyles}
				heading="Listing Removed"
				bodyContent={errorMessage}
			/>
		</>
	);
};
export default HorseDetailsInfo;
