import React, { useEffect, useState } from 'react';
import HeaderBanner from '../HeaderBanner';
import useStyles from './styles';
import { useParams, navigate } from '@reach/router';
import { useAuth } from '../../hooks/useAuth';
import {
	getHorseClaim,
	getHorses,
	getMemberData,
	getTransitionReport,
	getTransitionReportReferences,
	isClaimedByMember,
	saveHorseClaim,
	saveOTTStatusAndProgram,
	saveTransitionReport,
	saveHorseLocation,
	getTransitionReportById
} from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';
import { Col, Row, useScreenClass } from 'react-grid-system';
import {
	checkIsSmallScreen,
	checkUserAdmin,
	extractLookUpOptionsOther,
	formatDate,
	isMultiSelectOptionOther
} from '../../utils/helpers';
import FormLabelField from '../FormLabelField';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import Toggle from '../Toggle';
import { useToast } from '../../hooks/useToast';
import FormDropDown from '../FormDropDown';
import { isOtherSelectedDropdown, isOtherSelectedMulti } from './utils';
import FormAddressEntryGeneric, {
	ADDRESS_FIELDS
} from '../FormAddressEntryGeneric';
import PrimaryButton from '../PrimaryButton';
import {
	APPROVED,
	CommonKeys,
	DRAFT,
	HorseManagementKeys,
	REJECTED,
	REVIEW,
	ROLE_ID
} from '../../utils/constants';
import ResizingTextArea from '../ResizingTextArea';
import FormDatePicker from '../FormDatePicker';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import SubmitOnBehalf from '../SubmitOnBehalf';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';

const TransitionReport = () => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const { horseCode, id } = useParams();
	const [horse, setHorse] = useState();
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [currentUserMemberId, setCurrentUserMemberId] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [shouldValidate, setShouldValidate] = useState(false);
	const [isSubmitOnBehalf, setIsSubmitOnBehalf] = useState(false);
	const [selectedUserVerifiedInfo, setSelectedUserVerifiedInfo] = useState(
		null
	);
	const [isSelectUserRequired, setIsSelectUserRequired] = useState(false);
	const [selectUserRequiredError, setSelectUserRequiredError] = useState('');
	const { addToast } = useToast();
	const [
		hasClickedClaimThoroughbred,
		setHasClickedClaimThoroughbred
	] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [disableSubmitButton, setDisableSubmitbutton] = useState(false);

	const [transitionReportId, setTransitionReportId] = useState(null);
	const [hasBeenTransitioned, sethasBeenTransitioned] = useState(true);
	const [whoRequestedToAssist, setwhoRequestedToAssist] = useState(0);
	const [reasonForTransition, setreasonForTransition] = useState(0);
	const [retirementReason, setretirementReason] = useState(0);
	const [doesHorseHaveInjury, setdoesHorseHaveInjury] = useState(false);
	const [doesHorseHaveIllness, setdoesHorseHaveIllness] = useState(false);
	const [doesHorseHaveVices, setdoesHorseHaveVices] = useState(false);
	const [rehome, setrehome] = useState(0);
	const [lengthOfAdvertised, setlengthOfAdvertised] = useState(0);
	const [moveDate, setmoveDate] = useState(null);
	const [address, setaddress] = useState('');
	const [suburb, setsuburb] = useState('');
	const [postcode, setpostcode] = useState('');
	const [state, setstate] = useState('');
	const [country, setcountry] = useState('');
	const [isAddressManual, setisAddressManual] = useState(false);
	const [purpose, setpurpose] = useState(0);
	const [vices, setvices] = useState([]);
	const [injuries, setinjuries] = useState([]);
	const [illnesses, setillnesses] = useState([]);
	const [
		whoRequestedToAssistOtherDetails,
		setwhoRequestedToAssistOtherDetails
	] = useState('');
	const [
		reasonForTransitionOtherDetails,
		setreasonForTransitionOtherDetails
	] = useState('');
	const [
		retirementReasonOtherDetails,
		setretirementReasonOtherDetails
	] = useState('');
	const [injuriesOtherDetails, setinjuriesOtherDetails] = useState('');
	const [illnessesOtherDetails, setillnessesOtherDetails] = useState('');
	const [vicesOtherDetails, setvicesOtherDetails] = useState('');
	const [rehomeOtherDetails, setrehomeOtherDetails] = useState('');
	const [purposeOtherDetails, setpurposeOtherDetails] = useState('');

	const [injuriesOptions, setInjuriesOptions] = useState([]);
	const [illnessesOptions, setIllnessesOptions] = useState([]);
	const [vicesOptions, setVicesOptions] = useState([]);
	const [
		whoRequestedToAssistOptions,
		setWhoRequestedToAssistOptions
	] = useState([]);
	const [reasonForTransitionOptions, setReasonForTransitionOptions] = useState(
		[]
	);
	const [retirementReasonOptions, setRetirementReasonOptions] = useState([]);
	const [rehomeOptions, setRehomeOptions] = useState([]);
	const [lengthOfAdvertisedOptions, setLengthOfAdvertisedOptions] = useState(
		[]
	);
	const [purposeOptions, setPurposeOptions] = useState([]);

	const { currentUser } = useAuth();
	const isAdminUser = checkUserAdmin(currentUser?.Roles);

	const validateFields = () => {
		let errors = {};

		if (!whoRequestedToAssist || whoRequestedToAssist == 0)
			errors = {
				...errors,
				whoRequestedToAssist: { message: 'This field is required' }
			};
		if (
			isOtherSelectedDropdown(
				whoRequestedToAssist,
				whoRequestedToAssistOptions
			) &&
			!whoRequestedToAssistOtherDetails
		) {
			errors = {
				...errors,
				whoRequestedToAssistOtherDetails: { message: 'This field is required' }
			};
		}
		if (!reasonForTransition || reasonForTransition == 0)
			errors = {
				...errors,
				reasonForTransition: { message: 'This field is required' }
			};
		if (
			isOtherSelectedDropdown(
				reasonForTransition,
				reasonForTransitionOptions
			) &&
			!reasonForTransitionOtherDetails
		) {
			errors = {
				...errors,
				reasonForTransitionOtherDetails: { message: 'This field is required' }
			};
		}
		if (
			(!retirementReason || retirementReason == 0) &&
			reasonForTransition == 2
		)
			errors = {
				...errors,
				retirementReason: { message: 'This field is required' }
			};
		if (
			isOtherSelectedDropdown(retirementReason, retirementReasonOptions) &&
			!retirementReasonOtherDetails
		) {
			errors = {
				...errors,
				retirementReasonOtherDetails: { message: 'This field is required' }
			};
		}
		if (!rehome || rehome == 0)
			errors = {
				...errors,
				rehome: { message: 'This field is required' }
			};
		if (isOtherSelectedDropdown(rehome, rehomeOptions) && !rehomeOtherDetails) {
			errors = {
				...errors,
				rehomeOtherDetails: { message: 'This field is required' }
			};
		}
		if (!lengthOfAdvertised || lengthOfAdvertised == 0)
			errors = {
				...errors,
				lengthOfAdvertised: { message: 'This field is required' }
			};
		if (!purpose || purpose == 0)
			errors = {
				...errors,
				purpose: { message: 'This field is required' }
			};
		if (
			isOtherSelectedDropdown(purpose, purposeOptions) &&
			!purposeOtherDetails
		) {
			errors = {
				...errors,
				purposeOtherDetails: { message: 'This field is required' }
			};
		}
		if (doesHorseHaveInjury && (!injuries || injuries.length === 0)) {
			errors = { ...errors, injuries: { message: 'This field is required' } };
		}

		if (
			doesHorseHaveInjury &&
			isMultiSelectOptionOther(injuries) &&
			!injuriesOtherDetails
		) {
			errors = {
				...errors,
				injuriesOtherDetails: { message: 'This field is required' }
			};
		}

		if (doesHorseHaveIllness && (!illnesses || illnesses.length === 0)) {
			errors = { ...errors, illnesses: { message: 'This field is required' } };
		}

		if (
			doesHorseHaveIllness &&
			isMultiSelectOptionOther(illnesses) &&
			!illnessesOtherDetails
		) {
			errors = {
				...errors,
				illnessesOtherDetails: { message: 'This field is required' }
			};
		}

		if (doesHorseHaveVices && (!vices || vices.length === 0)) {
			errors = { ...errors, vices: { message: 'This field is required' } };
		}

		if (
			doesHorseHaveVices &&
			isMultiSelectOptionOther(vices) &&
			!vicesOtherDetails
		) {
			errors = {
				...errors,
				vicesOtherDetails: { message: 'This field is required' }
			};
		}

		if (!moveDate)
			errors = { ...errors, moveDate: { message: 'This field is required' } };

		if (!address && !isAddressManual)
			errors = { ...errors, address: { message: 'This field is required' } };
		if (!suburb && !isAddressManual)
			errors = { ...errors, suburb: { message: 'This field is required' } };
		if (!country)
			errors = { ...errors, country: { message: 'This field is required' } };
		if (!state)
			errors = { ...errors, state: { message: 'This field is required' } };
		if (!postcode && !isAddressManual)
			errors = { ...errors, postcode: { message: 'This field is required' } };

		if (errors) {
			setDisableSubmitbutton(true);
		}
		setUncontrolledErrors(errors);
		return errors;
	};

	useEffect(() => {
		fetchMemberData();
	}, [currentUser]);

	const fetchMemberData = async () => {
		if (currentUser?.UserId) {
			const result = await AppSyncService.execute(getMemberData, {
				externalId: currentUser.UserId
			});

			if (result?.data?.getMember) {
				setCurrentUserMemberId(result.data.getMember.id);
			}
		}
	};

	const onSelectUserVerified = (selectedUserVerified) => {
		if (selectedUserVerified?.id !== selectedUserVerifiedInfo?.id) {
			onClearFields();
		}
		setIsSelectUserRequired(false);
		setSelectedUserVerifiedInfo(selectedUserVerified);
	};

	const handleSave = async (isSubmit = false) => {
		const filteredVices = vices.map((vice) => vice.value);
		const filteredInjuries = injuries.map((injury) => injury.value);
		const filteredIllnesses = illnesses.map((illness) => illness.value);
		const payload = {
			id: transitionReportId ?? null,
			memberId: !isSubmitOnBehalf
				? currentUserMemberId
				: selectedUserVerifiedInfo.id,
			horseCode: +horseCode,
			isSubmitOnBehalf,
			hasBeenTransitioned,
			whoRequestedToAssistId: whoRequestedToAssist
				? Number(whoRequestedToAssist)
				: null,
			reasonForTransitionId: reasonForTransition
				? Number(reasonForTransition)
				: null,
			retirementReasonId: retirementReason ? Number(retirementReason) : null,
			doesHorseHaveInjury,
			doesHorseHaveIllness,
			doesHorseHaveVices,
			rehomeId: rehome ? Number(rehome) : null,
			lengthOfAdvertisedId: lengthOfAdvertised
				? Number(lengthOfAdvertised)
				: null,
			moveDate,
			address,
			suburb,
			postcode,
			state,
			country,
			isAddressManual,
			purposeId: purpose ? Number(purpose) : null,
			vices: filteredVices,
			injuries: filteredInjuries,
			illnesses: filteredIllnesses,
			whoRequestedToAssistOtherDetails,
			reasonForTransitionOtherDetails,
			retirementReasonOtherDetails,
			injuriesOtherDetails,
			illnessesOtherDetails,
			vicesOtherDetails,
			rehomeOtherDetails,
			purposeOtherDetails,
			status: isSubmit ? REVIEW : DRAFT
		};

		const data = await AppSyncService.execute(saveTransitionReport, {
			input: payload
		});
		if (data?.data?.saveTransitionReport !== null) {
			if (isSubmit) {
				const payload = {
					asOfDate: moveDate,
					horseCode,
					programId: 10, //@NOTE - Equivalent to Ack Rehoming in horse program
					statusId: 46, //@NOTE - Equivalent to Completed in horse status
					memberExternalId: currentUser.UserId
				};
				await AppSyncService.execute(saveOTTStatusAndProgram, payload);
				await AppSyncService.execute(saveHorseLocation, {
					input: {
						address: address,
						country: country,
						horseCode: +horseCode,
						postcode: postcode,
						state: state,
						suburb: suburb,
						sourceId: 20 //@NOTE: 20 is equal to Transition Report
					}
				});
				addToast({ Message: 'Transition Report Submitted', IsSuccess: true });
				navigate(DASHBOARD_ROUTE);
			} else addToast({ Message: 'Transition Report Saved', IsSuccess: true });
		} else
			addToast({
				Message: `Failed to ${isSubmit ? 'submit' : 'save'} transition report`,
				IsSuccess: false
			});
	};

	const handleOnSubmit = () => {
		const errors = Object.keys(validateFields());
		if (errors?.length > 0) {
			setDisableSubmitbutton(true);
			setIsShowErrorMessages(true);
		} else {
			handleSave(true);
		}
	};

	useEffect(() => {
		if (!id) {
			AppSyncService.execute(getHorses, { horseCode: +horseCode }).then(
				(result) => {
					if (result?.data?.getHorse) {
						setHorse(result.data.getHorse);
					}
				}
			);
		}
	}, []);

	useEffect(() => {
		if (isSelectUserRequired) {
			setSelectUserRequiredError('User is required');
		} else {
			setSelectUserRequiredError('');
		}
	}, [isSelectUserRequired]);

	useEffect(() => {
		if (currentUser) {
			AppSyncService.execute(getHorseClaim, {
				horseCodeForClaim: horseCode,
				memberExternalId: !isSubmitOnBehalf
					? currentUser?.UserId
					: selectedUserVerifiedInfo?.memberExternalId
			}).then((result) => {
				if (result.data?.getHorseClaim?.ownershipType) {
					setHasClickedClaimThoroughbred(true);
				}
			});
		}
	}, [currentUser, selectedUserVerifiedInfo]);

	useEffect(() => {
		if (currentUser) {
			AppSyncService.execute(isClaimedByMember, {
				horseCode,
				memberExternalId: !isSubmitOnBehalf
					? currentUser?.UserId
					: selectedUserVerifiedInfo?.memberExternalId
			}).then((result) => {
				if (result.data?.isClaimedByMember) {
					setIsOwner(true);
				}
			});
		}
	}, [currentUser, selectedUserVerifiedInfo]);

	useEffect(() => {
		if (shouldValidate) {
			const errors = Object.keys(validateFields());
			if (errors?.length > 0) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	useEffect(() => {
		if (currentUser !== null) {
			AppSyncService.execute(getTransitionReportReferences).then((result) => {
				if (result.data) {
					setInjuriesOptions(
						extractLookUpOptionsOther(result, 'listInjuryDetails')
					);
					setIllnessesOptions(
						extractLookUpOptionsOther(result, 'listAckRetirementIllnessTypes')
					);
					setVicesOptions(
						extractLookUpOptionsOther(result, 'listHabitsAndVices')
					);
					setWhoRequestedToAssistOptions(
						extractLookUpOptionsOther(result, 'listWhoRequestedToAssistLookup')
					);
					setReasonForTransitionOptions(
						extractLookUpOptionsOther(result, 'listReasonForTransitionLookup')
					);
					setRetirementReasonOptions(
						extractLookUpOptionsOther(result, 'listRetirementLookup')
					);
					setRehomeOptions(
						extractLookUpOptionsOther(result, 'listRehomeLookup')
					);
					setLengthOfAdvertisedOptions(
						extractLookUpOptionsOther(result, 'listLengthOfAdvertisedLookup')
					);
					setPurposeOptions(
						extractLookUpOptionsOther(result, 'listHorsePurposes')
					);

					setWhoRequestedToAssistOptions((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setReasonForTransitionOptions((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setRetirementReasonOptions((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setRehomeOptions((prev) => [{ id: 0, value: '' }].concat(prev));
					setLengthOfAdvertisedOptions((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setPurposeOptions((prev) => [{ id: 0, value: '' }].concat(prev));
				}
			});
		}
	}, []);

	useEffect(() => {
		if (currentUser !== null) {
			fetchTransitionReport();
		}
	}, [currentUser]);

	const fetchTransitionReport = () => {
		let query = getTransitionReport;
		let queryParams = {
			horseCode: +horseCode,
			memberExternalId: currentUser.UserId
		};
		if (id && isAdminUser) {
			query = getTransitionReportById;
			queryParams = { id: +id };
		}
		AppSyncService.execute(query, queryParams).then((result) => {
			if (result.data) {
				const initialData =
					id && isAdminUser
						? result?.data?.getTransitionReportById
						: result?.data?.getTransitionReport;

				if (initialData?.status == null) {
					setIsShowErrorMessages(false);
				} else {
					setIsShowErrorMessages(true);
				}
				if (id && isAdminUser) {
					setHorse(initialData.horse);
				}
				setTransitionReportId(initialData.id ?? null);
				sethasBeenTransitioned(initialData.hasBeenTransitioned ?? true);
				setwhoRequestedToAssist(initialData.whoRequestedToAssist?.id ?? 0);
				setreasonForTransition(initialData.reasonForTransition?.id ?? 0);
				setretirementReason(initialData.retirementReason?.id ?? 0);
				setdoesHorseHaveInjury(initialData.doesHorseHaveInjury ?? false);
				setdoesHorseHaveIllness(initialData.doesHorseHaveIllness ?? false);
				setdoesHorseHaveVices(initialData.doesHorseHaveVices ?? false);
				setrehome(initialData.rehome?.id ?? 0);
				setlengthOfAdvertised(initialData.lengthOfAdvertised?.id ?? 0);
				setmoveDate(initialData.moveDate ?? null);
				setaddress(initialData.address ?? '');
				setsuburb(initialData.suburb ?? '');
				setpostcode(initialData.postcode ?? '');
				setstate(initialData.state ?? '');
				setcountry(initialData.country ?? '');
				setisAddressManual(initialData.isAddressManual ?? false);
				setpurpose(initialData.purpose?.id ?? 0);
				if (initialData.vices.length > 0) {
					const vices = initialData.vices.map((vice) => ({
						value: vice.id,
						label: vice.value
					}));
					setvices(vices);
				}
				if (initialData.injuries.length > 0) {
					const injuries = initialData.injuries.map((injury) => ({
						value: injury.id,
						label: injury.value
					}));
					setinjuries(injuries);
				}
				if (initialData.illnesses.length > 0) {
					const illnesses = initialData.illnesses.map((illness) => ({
						value: illness.id,
						label: illness.value
					}));
					setillnesses(illnesses);
				}
				setwhoRequestedToAssistOtherDetails(
					initialData.whoRequestedToAssistOtherDetails ?? ''
				);
				setreasonForTransitionOtherDetails(
					initialData.reasonForTransitionOtherDetails ?? ''
				);
				setretirementReasonOtherDetails(
					initialData.retirementReasonOtherDetails ?? ''
				);
				setinjuriesOtherDetails(initialData.injuriesOtherDetails ?? '');
				setillnessesOtherDetails(initialData.illnessesOtherDetails ?? '');
				setvicesOtherDetails(initialData.vicesOtherDetails ?? '');
				setrehomeOtherDetails(initialData.rehomeOtherDetails ?? '');
				setpurposeOtherDetails(initialData.purposeOtherDetails ?? '');

				setIsReadOnly(
					id || initialData.status === REVIEW || initialData.status === APPROVED
				);

				if (initialData.status === DRAFT || initialData.status === REJECTED) {
					setDisableSubmitbutton(false);
					setShouldValidate(true);
				}
			}
		});
	};

	const claimThoroughbred = () => {
		const horseClaimData = {
			horseCode,
			ownershipType: 'OTT Owner',
			score: 0,
			isSubmitOnBehalf,
			memberId: !isSubmitOnBehalf
				? currentUserMemberId
				: selectedUserVerifiedInfo.id
		};
		AppSyncService.execute(saveHorseClaim, { horseClaimData }).then(
			(result) => {
				if (result.data?.saveHorseClaim?.id) {
					setHasClickedClaimThoroughbred(true);
					addToast({ Message: 'Claim horse form setup', IsSuccess: true });
				}
			}
		);
	};

	const updateDate = (value, stateAssignmentFunction) => {
		if (!value) stateAssignmentFunction(null);
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
	};

	const manualAddressCheckBoxData = {
		id: 'manualCheckBox',
		label: 'Manually add the address',
		value: 'Manually add the address',
		isSelected: isAddressManual
	};

	const addressAutoComplete = (address, addressDetails) => {
		setaddress(address);
		setsuburb(addressDetails.Suburb);
		setpostcode(addressDetails.Postcode);
		setstate(addressDetails.State);
		setcountry(addressDetails.Country);
		setShouldValidate(true);
	};

	const onClearFields = () => {
		setHasClickedClaimThoroughbred(false);
		setTransitionReportId(null);
		sethasBeenTransitioned(true);
		setwhoRequestedToAssist(0);
		setreasonForTransition(0);
		setretirementReason(0);
		setdoesHorseHaveInjury(false);
		setdoesHorseHaveIllness(false);
		setdoesHorseHaveVices(false);
		setrehome(0);
		setlengthOfAdvertised(0);
		setmoveDate(null);
		setaddress('');
		setsuburb('');
		setpostcode('');
		setstate('');
		setcountry('');
		setisAddressManual(false);
		setpurpose(0);
		setvices([]);
		setinjuries([]);
		setillnesses([]);
		setwhoRequestedToAssistOtherDetails('');
		setreasonForTransitionOtherDetails('');
		setretirementReasonOtherDetails('');
		setinjuriesOtherDetails('');
		setillnessesOtherDetails('');
		setvicesOtherDetails('');
		setrehomeOtherDetails('');
		setpurposeOtherDetails('');
		setIsShowErrorMessages(false);
		setUncontrolledErrors({});
	};

	const onToggleSubmitBehalf = (submitOnBehalf) => {
		setIsSubmitOnBehalf(submitOnBehalf);
		onClearFields();
		if (!submitOnBehalf) {
			fetchTransitionReport();
		}
	};

	return (
		<>
			<PageContainer>
				<SEO title="Transition Report" />
				{isAdminUser && !isReadOnly ? (
					<SubmitOnBehalf
						typeOfUsers={ROLE_ID.ACK_REHOMER}
						horseCode={horseCode}
						onToggleSubmitBehalf={onToggleSubmitBehalf}
						onSelectUserVerified={onSelectUserVerified}
						isGetAllUsersVerified={false}
						selectUserRequiredError={selectUserRequiredError}
						isSelectUserRequired={isSelectUserRequired}
					/>
				) : null}
				<div
					style={
						isSmallScreen
							? { border: 'none' }
							: {
									borderTop: '1px solid #b3bcc1',
									borderRadius: '5px 5px 0px 0px',
									borderBottom: 'none'
							  }
					}
					className={
						isSmallScreen
							? classes.sectionContainerMobile
							: classes.sectionContainer
					}
				>
					<HeaderBanner
						title="Transition Report"
						mobile={isSmallScreen}
						styles={{
							marginBottom: 0,
							marginTop: 0,
							borderRadius: '5px 5px 0px 0px'
						}}
					/>
					<HeaderBanner
						title="Horse Details"
						mobile={isSmallScreen}
						type="info"
						styles={{ marginBottom: 0, marginTop: 0, borderRadius: '0px' }}
					/>
					<div
						className={
							isSmallScreen
								? classes.sectionContainerMobile
								: classes.sectionContainer
						}
						style={{ backgroundColor: '#f0f0f0' }}
					>
						<Row className={classes.d}>
							<Col xs={12} lg={4}>
								<FormLabelField title="Name" value={horse?.name} />
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField title="Colour" value={horse?.colour} />
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField title="Sex" value={horse?.sex} />
							</Col>
						</Row>
						<Row className={classes.d}>
							<Col xs={12} lg={4}>
								<FormLabelField title="Age" value={horse?.age} />
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Microchip Number"
									value={horse?.microChipNumber}
								/>
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField title="Status" value={horse?.status} />
							</Col>
						</Row>
					</div>
					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has this horse been transitioned into a new home?
								</div>
								<Toggle
									id={'hasBeenTransitioned'}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasBeenTransitioned(value);
										setShouldValidate(true);
									}}
									value={hasBeenTransitioned}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
					</Row>
					{hasBeenTransitioned && (
						<>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Who requested you to assist with rehoming this horse?*
										</div>
										<FormDropDown
											showBlank
											id={'whoRequestedToAssist'}
											items={whoRequestedToAssistOptions}
											selectedId={whoRequestedToAssist}
											onChange={(e) => {
												setwhoRequestedToAssist(e.target.value);
												setShouldValidate(true);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.whoRequestedToAssist?.message}
											</span>
										)}
									</div>
								</Col>
								{isOtherSelectedDropdown(
									whoRequestedToAssist,
									whoRequestedToAssistOptions
								) && (
									<Col xs={12} lg={8}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													rows={2}
													isShowRemainingChars={false}
													name="whoRequestedToAssistOtherDetails"
													title="Other party*"
													id="resetexpress-whoRequestedToAssistOtherDetails"
													value={whoRequestedToAssistOtherDetails}
													onChange={(e) => {
														setwhoRequestedToAssistOtherDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{
														uncontrolledErrors.whoRequestedToAssistOtherDetails
															?.message
													}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Reason for transition*
										</div>
										<FormDropDown
											showBlank
											id={'reasonForTransition'}
											items={reasonForTransitionOptions}
											selectedId={reasonForTransition}
											onChange={(e) => {
												setreasonForTransition(e.target.value);
												setShouldValidate(true);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.reasonForTransition?.message}
											</span>
										)}
									</div>
								</Col>
								{isOtherSelectedDropdown(
									reasonForTransition,
									reasonForTransitionOptions
								) && (
									<Col xs={12} lg={8}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													rows={2}
													isShowRemainingChars={false}
													name="reasonForTransitionOtherDetails"
													title="Other reason*"
													id="resetexpress-reasonForTransitionOtherDetails"
													value={reasonForTransitionOtherDetails}
													onChange={(e) => {
														setreasonForTransitionOtherDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{
														uncontrolledErrors.reasonForTransitionOtherDetails
															?.message
													}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Retirement reason{reasonForTransition == 2 ? '*' : ''}
										</div>
										<FormDropDown
											showBlank
											id={'retirementReason'}
											items={retirementReasonOptions}
											selectedId={retirementReason}
											onChange={(e) => {
												setretirementReason(e.target.value);
												setShouldValidate(true);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.retirementReason?.message}
											</span>
										)}
									</div>
								</Col>
								{isOtherSelectedDropdown(
									retirementReason,
									retirementReasonOptions
								) && (
									<Col xs={12} lg={8}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													rows={2}
													isShowRemainingChars={false}
													name="retirementReasonOtherDetails"
													title="Other reason*"
													id="resetexpress-retirementReasonOtherDetails"
													value={retirementReasonOtherDetails}
													onChange={(e) => {
														setretirementReasonOtherDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{
														uncontrolledErrors.retirementReasonOtherDetails
															?.message
													}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Does the horse have any known injuries (previously or
											current)?
										</div>
										<Toggle
											id={'doesHorseHaveInjury'}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setdoesHorseHaveInjury(value);
												setShouldValidate(true);
											}}
											value={doesHorseHaveInjury}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{doesHorseHaveInjury && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<FormLabelField
												title={`Please select injuries*`}
												value={injuries.map((g) => g.label).join()}
												style={{ padding: 0 }}
											/>
										) : (
											<div className={classes.fieldContainer}>
												<MultiSelectInputField
													idPrefix={`injuries`}
													fieldLabel={`Please select injuries*`}
													options={injuriesOptions}
													selectedIds={injuries}
													onSelectChange={(vals) => {
														setinjuries(vals);
														setShouldValidate(true);
													}}
													otherDisabled={true}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorText}>
														{uncontrolledErrors.injuries?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								)}
								{doesHorseHaveInjury && isOtherSelectedMulti(injuries) && (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													rows={2}
													isShowRemainingChars={false}
													name="injuriesOtherDetails"
													title="Other details*"
													id="resetexpress-injuriesOtherDetails"
													value={injuriesOtherDetails}
													onChange={(e) => {
														setinjuriesOtherDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.injuriesOtherDetails?.message}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Does the horse have any known illness (previously or
											current)?
										</div>
										<Toggle
											id={'doesHorseHaveIllness'}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setdoesHorseHaveIllness(value);
												setShouldValidate(true);
											}}
											value={doesHorseHaveIllness}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{doesHorseHaveIllness && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<FormLabelField
												title={`Please select illness*`}
												value={illnesses.map((g) => g.label).join()}
												style={{ padding: 0 }}
											/>
										) : (
											<div className={classes.fieldContainer}>
												<MultiSelectInputField
													idPrefix={`illnesses`}
													fieldLabel={`Please select illnesses*`}
													options={illnessesOptions}
													selectedIds={illnesses}
													onSelectChange={(vals) => {
														setillnesses(vals);
														setShouldValidate(true);
													}}
													otherDisabled={true}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorText}>
														{uncontrolledErrors.illnesses?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								)}
								{doesHorseHaveIllness && isOtherSelectedMulti(illnesses) && (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													rows={2}
													isShowRemainingChars={false}
													name="illnessesOtherDetails"
													title="Other details*"
													id="resetexpress-illnessesOtherDetails"
													value={illnessesOtherDetails}
													onChange={(e) => {
														setillnessesOtherDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.illnessesOtherDetails?.message}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Does the horse have any known behaviour concerns or vices?
										</div>
										<Toggle
											id={'doesHorseHaveVices'}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setdoesHorseHaveVices(value);
												setShouldValidate(true);
											}}
											value={doesHorseHaveVices}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{doesHorseHaveVices && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<FormLabelField
												title={`Please select vices/behaviours*`}
												value={vices.map((g) => g.label).join()}
												style={{ padding: 0 }}
											/>
										) : (
											<div className={classes.fieldContainer}>
												<MultiSelectInputField
													idPrefix={`vices`}
													fieldLabel={`Please select vices*`}
													options={vicesOptions}
													selectedIds={vices}
													onSelectChange={(vals) => {
														setvices(vals);
														setShouldValidate(true);
													}}
													otherDisabled={true}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorText}>
														{uncontrolledErrors.vices?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								)}
								{doesHorseHaveVices && isOtherSelectedMulti(vices) && (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													rows={2}
													isShowRemainingChars={false}
													name="vicesOtherDetails"
													title="Other details*"
													id="resetexpress-vicesOtherDetails"
													value={vicesOtherDetails}
													onChange={(e) => {
														setvicesOtherDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.vicesOtherDetails?.message}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											How did you rehome the horse?*
										</div>
										<FormDropDown
											showBlank
											id={'rehome'}
											items={rehomeOptions}
											selectedId={rehome}
											onChange={(e) => {
												setrehome(e.target.value);
												setShouldValidate(true);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.rehome?.message}
											</span>
										)}
									</div>
								</Col>
								{isOtherSelectedDropdown(rehome, rehomeOptions) && (
									<Col xs={12} lg={8}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													rows={2}
													isShowRemainingChars={false}
													name="rehomeOtherDetails"
													title="Other source*"
													id="resetexpress-rehomeOtherDetails"
													value={rehomeOtherDetails}
													onChange={(e) => {
														setrehomeOtherDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.rehomeOtherDetails?.message}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											What length of time was the horse advertised for?*
										</div>
										<FormDropDown
											showBlank
											id={'lengthOfAdvertised'}
											items={lengthOfAdvertisedOptions}
											selectedId={lengthOfAdvertised}
											onChange={(e) => {
												setlengthOfAdvertised(e.target.value);
												setShouldValidate(true);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.lengthOfAdvertised?.message}
											</span>
										)}
									</div>
								</Col>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											What date did the horse move to the new owner?*
										</div>
										<FormDatePicker
											noMaxDate
											id="horse_move_new_owner"
											DateValue={moveDate}
											updateSelection={(value) => {
												updateDate(value, setmoveDate);
												setShouldValidate(true);
											}}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.moveDate?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
							<div className={classes.fieldContainer}>
								<FormAddressEntryGeneric
									errorWithoutBorder
									address={address}
									disabled={isReadOnly}
									requiredFields={
										isAddressManual
											? [ADDRESS_FIELDS.STATE, ADDRESS_FIELDS.COUNTRY]
											: [
													ADDRESS_FIELDS.ADDRESS,
													ADDRESS_FIELDS.SUBURB,
													ADDRESS_FIELDS.POSTCODE,
													ADDRESS_FIELDS.STATE,
													ADDRESS_FIELDS.COUNTRY
											  ]
									}
									onAddressChange={(value) => {
										setaddress(value);
									}}
									isPostalAddressManual={false}
									suburb={suburb}
									onSuburbChange={(value) => {
										setsuburb(value);
										setShouldValidate(true);
									}}
									postcode={postcode}
									onPostCodeChange={(value) => {
										setpostcode(value);
										setShouldValidate(true);
									}}
									country={country}
									onCountryChange={(value) => {
										setcountry(value);
										setShouldValidate(true);
									}}
									manualAddressCheckBoxData={manualAddressCheckBoxData}
									toggleManualAddressCheckBox={() => {
										setisAddressManual((prev) => !prev);
										setShouldValidate(true);
									}}
									state={state}
									onAddressStateChange={(value) => {
										setstate(value);
										setShouldValidate(true);
									}}
									isSubmitClicked={isShowErrorMessages}
									autoCompleteFunction={addressAutoComplete}
								/>
							</div>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											What purpose does the new owner intend for the horse?*
										</div>
										<FormDropDown
											showBlank
											id={'purpose'}
											items={purposeOptions}
											selectedId={purpose}
											onChange={(e) => {
												setpurpose(e.target.value);
												setShouldValidate(true);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.purpose?.message}
											</span>
										)}
									</div>
								</Col>
								{isOtherSelectedDropdown(purpose, purposeOptions) && (
									<Col xs={12} lg={8}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													rows={2}
													isShowRemainingChars={false}
													name="purposeOtherDetails"
													title="Other source*"
													id="resetexpress-purposeOtherDetails"
													value={purposeOtherDetails}
													onChange={(e) => {
														setpurposeOtherDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.purposeOtherDetails?.message}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
						</>
					)}
					{!hasBeenTransitioned && !hasClickedClaimThoroughbred && !isOwner && (
						<>
							<Col xs={12}>
								<div className={classes.claimThorougbredLinkContainer}>
									<Col lg={8} xs={12} style={{ fontSize: 15 }}>
										If the horse is currently in your possession, please also
										claim the horse to add to your stable and update the
										ownership information
									</Col>
									<Col lg={4} sm={12}>
										<div
											style={{
												width: '100%',
												height: '100%',
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'center'
											}}
										>
											<button
												className={classes.claimButton}
												onClick={() => claimThoroughbred()}
											>
												Claim Thoroughbred
											</button>
										</div>
									</Col>
								</div>
							</Col>
						</>
					)}

					<div className={classes.submitContainer}>
						<div
							className={
								isSmallScreen ? classes.saveRequestMobile : classes.saveRequest
							}
						>
							<span className={isSmallScreen ? null : classes.saveButton}>
								<PrimaryButton
									id={CommonKeys.SAVE}
									isNonSubmit={false}
									style={{ minWidth: '170px' }}
									onClick={() => handleSave()}
									disabled={
										isReadOnly ||
										(isSubmitOnBehalf && !selectedUserVerifiedInfo)
									}
								>
									Save
								</PrimaryButton>
							</span>
							<span>
								<PrimaryButton
									id={HorseManagementKeys.SUBMIT_REPORT}
									isNonSubmit={false}
									disabled={
										disableSubmitButton ||
										isReadOnly ||
										(isSubmitOnBehalf && !selectedUserVerifiedInfo)
									}
									style={{ minWidth: '170px' }}
									onClick={() => handleOnSubmit()}
								>
									Submit Report
								</PrimaryButton>
							</span>
						</div>
					</div>
				</div>
			</PageContainer>
		</>
	);
};
export default TransitionReport;
