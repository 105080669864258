import { useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Container, useScreenClass } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getHorseInterestAndDetails,
	getHorseOTTStatus,
	isClaimedByMember
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import {
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_RV_EQUINE_WELFARE
} from '../../utils/constants';
import HorseDetailsInfo from '../HorseDetailsInfo';
import LoaderSpinner from '../LoaderSpinner';
import SEO from '../seo';
import useStyles from './styles';

const HorseDetails = () => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [horseInfo, setHorseInfo] = useState(null);
	const [horsePlacementData, setHorsePlacementData] = useState(null);
	const { addToast } = useToast();
	const { id } = useParams();
	const screenClassForRender = useScreenClass();
	const { currentUser } = useAuth();
	const [isOwner, setIsOwner] = useState(false);
	const [isClaimed, setIsClaimed] = useState(false);
	const [isAssigned, setIsAssigned] = useState(false);
	const [horseOTTStatusID, setHorseOTTStatusID] = useState(0);

	useEffect(() => {
		if (id != null) {
			const horseCode = id;
			const memberExternalId = currentUser?.UserId;
			setIsLoading(true);
			AppSyncService.execute(getHorseInterestAndDetails, {
				memberExternalId: memberExternalId,
				horseCode: horseCode
			}).then((data) => {
				try {
					setHorseInfo(data.data.getHorsePlacementInterest.horsePlacementForm);
					setHorsePlacementData(data.data.getHorsePlacementInterest);
					if (
						data?.data?.getHorsePlacementInterest?.horsePlacementForm?.member
							?.externalId == memberExternalId
					) {
						setIsOwner(true);
					}
					AppSyncService.execute(isClaimedByMember, {
						horseCode: +horseCode,
						memberExternalId: memberExternalId
					}).then((result) => {
						if (result.data?.isClaimedByMember) {
							setIsClaimed(true);
						}
					});
					if (
						data?.data?.getHorsePlacementInterest?.horsePlacementForm
							?.assignedRetrainerDetails?.externalId == memberExternalId
					) {
						setIsAssigned(true);
					}
					AppSyncService.execute(getHorseOTTStatus, {
						horseCode: horseCode
					}).then((result) => {
						const {
							data: { getHorseOTTStatus }
						} = result;
						if (getHorseOTTStatus) {
							setHorseOTTStatusID(getHorseOTTStatus.horseOTTStatusId);
						}
					});
				} catch {
					addToast({ Message: 'Unable to retrieve data', IsSuccess: false });
				}
				setIsLoading(false);
			});
		}
	}, []);

	return (
		<>
			<Container
				className={
					!['xs', 'sm', 'md'].includes(screenClassForRender)
						? classes.pageContainer
						: classes.pageContainerMobile
				}
			>
				<SEO title="Available for Retraining" />
				{!isLoading && horseInfo != null && (
					<HorseDetailsInfo
						data={horseInfo}
						placementData={horsePlacementData}
						isOwner={isOwner}
						isClaimed={isClaimed}
						isAssigned={isAssigned}
						isAdmin={currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE)}
						isRetrainer={currentUser?.Roles.includes(
							ROLE_RV_ACKNOWLEDGED_RETRAINER
						)}
						horseOTTStatusID={horseOTTStatusID}
					/>
				)}
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
			</Container>
		</>
	);
};
export default HorseDetails;
